.modal-xl {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 30;
    .modal-bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#000, 0.75);
        opacity: 0;
        transition: all 400ms 400ms ease;
        z-index: 31;
    }
    .panel {
        position: fixed;
        top: 50%;
        left: 50%;
        margin: 0;
        width: calc(100% - 40px);
        max-width: 1000px;
        transform: translate(-50%, 100px);
        opacity: 0;
        transition: all 400ms ease;
        z-index: 32;
        .body {
            max-height: calc(100vh - 160px);
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }
    }
    &.is-active {
        visibility: visible;
        .modal-bg {
            opacity: 1;
            transition: all 400ms ease;
        }
        .panel {
            opacity: 1;
            transform: translate(-50%, -50%);
            transition: all 400ms 400ms ease;
        }
    }

}