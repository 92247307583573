.topbar {
    position: fixed;
    top: 0;
    left: 0;
    padding: 10px 70px;
    width: 100%;
    height: 60px;
    background-color: #fff;
    box-shadow: 0 0 3px 0 rgba(#000, 0.75);
    text-align: center;
    z-index: 20;
    img {
        height: 40px;
    }
}

.menu-btn,
.more-btn {
    position: absolute;
    top: 0;
    width: 60px;
    height: 60px;
    color: #fff;
    background-color: #da9500;
    overflow: hidden;
    cursor: pointer;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -60px;
        width: 60px;
        height: 60px;
        background-color: #b67e2c;
        transition: all 200ms ease;
    }
    &:hover:before {
        left: 0;
    }
}

.menu-btn {
    left: 0;
    span {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 2px;
        border-radius: 2px;
        background-color: #fff;
        transform: translate(-50%, -50%);
        transition: transform 200ms ease, margin-top 200ms 200ms ease, opacity 400ms ease;
        &:first-child {
            margin-top: -6px;
        }
        &:last-child {
            margin-top: 6px;
        }
    }
    &:before {
        left: 60px;
    }
    &.is-active span {
        margin-top: 0;
        transition: transform 200ms 200ms ease, margin-top 200ms ease, opacity 400ms ease;
        &:first-child {
            transform: translate(-50%, -50%) rotate(45deg);
        }
        &:nth-child(2) {
            opacity: 0;
        }
        &:last-child {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
}

.more-btn {
    right: 0;
    i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 400ms ease;
    }
    &.is-active i {
        transform: translate(-50%, -50%) rotate(90deg);
    }
}

@media only screen and (min-width: 600px) {
    .menu-btn span {
        transition: transform 200ms ease, margin-top 200ms 200ms ease, width 0ms 200ms ease;
    }
    .menu-btn.is-active span {
        transition: margin-top 200ms ease, transform 200ms 200ms ease, width 0ms 200ms ease;
        &:first-child {
            width: 10px;
            transform: translate(-11px, 2px) rotate(45deg);
        }
        &:nth-child(2) {
            opacity: 1;
        }
        &:last-child {
            width: 10px;
            transform: translate(-11px, -4px) rotate(-45deg);
        }
    }
}