*, *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    position: relative;
}

html {
    box-sizing: border-box;
    -webkit-text-size-adjust: 100%;
}

body {
    line-height: 2;
    font-family: 'rawline', sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: #212121;
}

body::-webkit-scrollbar {
    width: 5px;
}
 
body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: #f7bb39;
}

#app {
    height: 100vh;
}

.removeElement{
    visibility:hidden;
    display:none;
}

body.scroll {
    overflow: auto !important;
}

.toasted-primary {
    background-color: #DA951F !important;
    color: black !important;
    font-size: 12px !important;
}

.toasted-default {
    font-size: 12px !important;
}

.green-row {
    background-color:#99f3bd;
    color:black;
    font-weight:bold;
}

.yellow-row {
    background-color:#ffd57e;
    color:black;
    font-weight:bold;
}

.red-row {
    background-color:#ffcbcb;
    color:black;
    font-weight:bold;
}