@import '_variables.scss';

.md_checkbox {
  width: auto;
  margin: 16px 8px 16px 0;
  display: inline-flex;
  position: relative;
  left: 4%;

  &:not(.md-disabled) {
    cursor: pointer;
    .md-checkbox-label {
      cursor: pointer;
    }
  }
  .md-checkbox-container {
    width: $checkbox-size;
    min-width: $checkbox-size;
    height: $checkbox-size;
    position: relative;
    border-radius: 2px;
    border: 2px solid rgba(#000, .54);
    transition: $swift-ease-out;

    &:focus {
      outline: none;
    }

    &:before {
      width: $checkbox-touch-size;
      height: $checkbox-touch-size;
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      transition: $swift-ease-in;
      content: " ";
    }

    &:after {
      width: 6px;
      height: 13px;
      position: absolute;
      top: 0;
      left: 5px;
      border: 2px solid #fff;
      border-top: 0;
      border-left: 0;
      opacity: 0;
      transform: rotate(45deg) scale3D(.15, .15, 1);
      transition: $swift-ease-in;
      content: " ";
    }

    input {
      position: absolute;
      left: -999em;
      
    }


    .md-ink-ripple {
      top: -16px;
      right: -16px;
      bottom: -16px;
      left: -16px;
      border-radius: 50%;
      color: rgba(#000, .54);

      .md-ripple {
        width: $checkbox-touch-size !important;
        height: $checkbox-touch-size !important;
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
      }
    }
  }


  .md-checkbox-label {
    height: $checkbox-size;
    padding-left: 8px;
    line-height: $checkbox-size;
  }
}
.md_checkbox.md_checked 
{
    background: #da9500;
  .md_checkbox-container
  {
    &:after 
    {
      opacity: 1;
      transform: rotate(45deg) scale3D(1, 1, 1);
      transition: $swift-ease-out;
    }
  }
}

.md-checkbox-container input {
  display: none;
}

/* The slider */
.slider {
  content:"Hello";
  float:left;
  /*position: absolute;*/
  cursor: pointer;
  width: 60px;
  height: 34px;
  background-color: #ccc;
  -webkit-transition: .4s;
  margin-left: 9px;
  margin-bottom: 9px;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  float:left;
  /*position: absolute;*/
  content: "";
  height: 26px;
  width: 26px;
  margin-left: 7%;
  margin-top: 7%;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #da9500;
}

input:focus + .slider {
  box-shadow: 0 0 1px #da9500;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

