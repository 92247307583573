.input-field {
    position: relative;
    padding: 10px 10px 20px 10px;
    width: 100%;
    label {
        position: absolute;
        top: 10px;
        left: 10px;
        color: #9e9e9e;
        transition: all 400ms ease;
    }
    input[type="date"]{
        border: none;
        border-bottom: 1px solid #9e9e9e;
        border-radius: 0;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: 1.5;
        background-color: transparent;
        outline: 0;
        transition: all 400ms ease;
        &.search {
            padding-right: 24px;
        } 
    }

    input[type="email"],
    input[type="password"],
    input[type="text"] {
        position: relative;
        width: 100%;
        border: none;
        border-bottom: 1px solid #9e9e9e;
        border-radius: 0;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: 1.5;
        background-color: transparent;
        outline: 0;
        transition: all 400ms ease;
        &.search {
            padding-right: 24px;
        }  
    }
    &.input_small
    {
        width: 49.49%;
        display: inline-block;

    }
    .error {
        position: absolute;
        bottom: 10px;
        left: 10px;
        width: calc(100% - 20px);
        line-height: 1;
        font-size: 10px;
        color: #f44336;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        opacity: 0;
        transition: all 400ms ease;
        &.is-visible {
            opacity: 1;
        }
    }
    i {
        position: absolute;
        top: 15px;
        right: 10px;
        font-size: 21px;
        color: #9e9e9e;
    }
    &.has-value {
        label {
            top: 0;
            font-size: 10px;
        }
    }
    &.is-focused {
        label {
            top: 0;
            font-size: 10px;
            color: #da9500;
        }
        input[type="email"],
        input[type="password"],
        input[type="text"] {
            border-color: #da9500;
            box-shadow: 0 1px 0 0 #da9500;
        }
        i {
            color: #da9500;
        }
    }
    .error-input {
      border-color: #E84444;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(232,68,68,.6);
    }
}
