.dropdown-vue{
    visibility: hidden;
    position: absolute;
    width: calc(100% - 20px);
    background-color: #fff;
    box-shadow: 0 0 2px 0 rgba(#000, 0.75);
    opacity: 0;
    z-index: 1;
    .dropdown-item {
        padding: 4px 10px;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: #fff;
        cursor: pointer;
        transition: all 400ms ease;
        &.is-selected {
            background-color: #eee;
        }
        &.is-empty {
            font-style: italic;
            text-align: center;
        }
    }
    &.is-visible {
        visibility: visible;
        opacity: 1;
    }
}