.btn {
    display: inline-block;
    position: relative;
    margin: 0 10px 20px 10px;
    padding: 8px;
    border: 2px solid #da9500;
    border-radius: 0;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 2px;
    line-height: 1;
    color: #fff;
    background-color: #da9500;
    text-transform: uppercase;
    outline: 0;
    cursor: pointer;
    transition: all 400ms ease;
    text-decoration: none;
    text-align: center;
    span {
        transition: all 400ms ease;
    }
    &:hover {
        border-color: #b67e2c;
        color: #fff;
        background-color: #b67e2c;
    }
    &.is-loading {
        span {
            opacity: 0;
        }
        .loader {
            opacity: 1;
        }
    }
}

.btn-alert {
    display: inline-block;
    position: relative;
    margin: 0 10px 20px 10px;
    padding: 8px;
    border: 2px solid red;
    border-radius: 0;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 2px;
    line-height: 1;
    color: #fff;
    background-color: red;
    text-transform: uppercase;
    outline: 0;
    cursor: pointer;
    transition: all 400ms ease;
    text-decoration: none;
    text-align: center;
    span {
        transition: all 400ms ease;
    }
    &:hover {
        border-color: #ac2925;
        color: #fff;
        background-color: #ac2925;
    }
    &.is-loading {
        span {
            opacity: 0;
        }
        .loader {
            opacity: 1;
        }
    }
}

.btn-gray {
    border-color: #616161;
    background-color: #616161;
    &:hover {
        border-color: #424242;
        background-color: #424242;
    }
}

.btn-green {
    border-color: #669900;
    background-color: #669900;
    font-weight: bold;
    &:hover {
        border-color: #446600;
        background-color: #446600;
    }
}

.btn-red {
    border-color: #d43f3a;
    background-color: #d9534f;
    font-weight: bold;
    &:hover {
        border-color: #ac2925;
        background-color: #c9302c;
    }
}

.btn-dark {
    border-color: #212121;
    background-color: #212121;
    &:hover {
        border-color: #424242;
        background-color: #424242;
    }
}

.btn-border {
    color: #da9500;
    background-color: transparent;
}