.panel {
    margin: 0 10px;
    width: calc(100% - 20px);
    box-shadow: 0 0 3px 0 rgba(#000, 0.7);
    .header {
        padding: 10px;
        font-family: Montserrat, sans-serif;
        font-weight: 700;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-align: center;
        background-color: #eee;
    }
    .body {
        padding: 20px 10px 0 10px;
        background-color: #fff;
        h5 {
            padding: 0 10px;
            color: #757575;
            text-transform: uppercase;
        }
        p {
            padding: 0 10px 20px 10px;
            text-align: center;
        }
        .map {
            margin: 0 10px 20px 10px;
            height: 300px;
            background-color: #bdbdbd;

            .full
            {
                width:100%;
            }
        }

        .vuemap {
            margin: 0 10px 20px 10px;
            height: 600px;
            background-color: #bdbdbd;

            .full
            {
                width:100%;
            }
        }
    }
    .actions {
        padding: 20px 10px 0 10px;
        background-color: #fff;
        text-align: center;
        &.has-cancel .btn {
            min-width: 103px;
        }
    }
    .a-link {
        display: block;
        text-align: center;
    }
}
.panel-new {
    display: flex;
    width: 65%;
    float: left;
    flex-wrap: wrap;
    .body {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        h5 {
            padding: 0 10px;
            width: 100%;
            color: #757575;
            text-transform: uppercase;
        }
        .input-field {
            width: 50%;
            &.input-field.full {
                width: 100%;
            }
        }
        .btn.btn-dark {
            margin: 0 auto 20px auto;
        }
    }
    //Regla para el mapa total
    .map {
        margin: 0 10px 20px 10px;
        width: 100%;
        height: 800px;
        background-color: #bdbdbd;
    }
    .actions {
        width: 100%;
        text-align: center;
    }
}
.map {
    margin: 0 10px 20px 10px;
    height: 300px;
    background-color: #bdbdbd;

    &.full
    {
        width:100%;
        height: 89%;
        
    }
}

.tracking-map {
    margin: 0 10px 20px 10px;
    height: 600px;
    background-color: #bdbdbd;

    &.full
    {
        width:100%;
        height: 89%;
        
    }
}

.medium-map {
    margin: 0 10px 20px 10px;
    height: 500px;
    background-color: #bdbdbd;

    &.full
    {
        width:100%;
        height: 89%;
        
    }
}

.panel-small-center {
    margin: 0 auto;
    max-width: 350px;
}

.order-form,
.store-form,
.customer-form {
    display: flex;
    flex-wrap: wrap;
    .body {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        h5 {
            padding: 0 10px;
            width: 100%;
            color: #757575;
            text-transform: uppercase;
        }
        .input-field {
            width: 50%;
            &.input-field.full {
                width: 100%;
            }
        }
        .btn.btn-dark {
            margin: 0 auto 20px auto;
        }
    }
    //Regla para el mapa total
    .map {
        margin: 0 10px 20px 10px;
        width: 100%;
        height: 300px;
        background-color: #bdbdbd;
    }
    .actions {
        width: 100%;
        text-align: center;
    }
}

.webhook-form, {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    .body {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        h5 {
            padding: 0 10px;
            width: 100%;
            color: #757575;
            text-transform: uppercase;
        }
        .input-field, {
            width: 50%;
            &.input-field.full {
                width: 100%;
            }
        }
        select {
            width: 30%;
            display: block;
            margin: 0 auto;
        }
        .btn.btn-dark {
            margin: 0 auto 20px auto;
        }
    }
    .actions {
        width: 100%;
        text-align: center;
    }
}

.webhook2-form, {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    float: left;
    .body {
        width: 100%;
        h5 {
            padding: 0 10px;
            width: 100%;
            color: #757575;
            text-transform: uppercase;
        }
        .input-field, {
            width: 50%;
            &.input-field.full {
                width: 100%;
            }
        }
        select {
            width: 30%;
            display: block;
            margin: 0 auto;
        }
        .btn.btn-dark {
            margin: 0 auto 20px auto;
        }
    }
    .actions {
        width: 100%;
        text-align: center;
    }
}

.clearfix{
    clear:both
}

.company-form {
    display: flex;
    flex-wrap: wrap;
    .body {
        display: flex;
        flex-wrap: wrap;
        width: 75%;
        h5 {
            padding: 0 10px;
            width: 100%;
            color: #757575;
            text-transform: uppercase;
        }
        .input-field {
            width: 50%;
            &.input-field.full {
                width: 100%;
            }
        }
        .btn.btn-dark {
            margin: 0 auto 20px auto;
        }
    }
    .actions {
        width: 75%;
        text-align: center;
    }
}

@media only screen and (min-width: 375px) {
    .panel-register-store .body {
        display: flex;
        flex-wrap: wrap;
        h5 {
            width: 100%;
        }
        .input-field {
            width: 50%;
            &.full {
                width: 100%;
            }
        }
        .map {
            width: 100%;
        }
    }
}

@media only screen and (min-width: 700px) {
    .panel-register-store {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        .header,
        .actions {
            width: 100%;
        }
        .body {
            width: 400px;
        }
        .body.has-map {
            width: calc(100% - 400px);
            .map {
                height: auto;
            }
        }
    }
}
//Regla para menos de 850px
@media only screen and (min-width: 850px) {
    .order-form .body,
    .store-form .body,
    .customer-form .body {
        width: 350px;
    }
    .order-form .map,
    .store-form .map,
    .customer-form .map {
        width: calc(100% - 370px);
        height: auto;
    }
}

// Accordion
.accordion {
    font-family: Lato;
    margin-bottom: 20px;
  
    background-color: #ECC97D; 
    // border-color: #ec5366;
    border: 10px solid #da9500;
    border-width: 1px;
    border-radius: 6px;
}
  
.accordion .header {
    height: 40px;
    line-height: 40px;
    padding: 0 40px 0 8px;
    position: relative;
    color: #000;
    font-weight: bold;
    cursor: pointer;
}

.accordion .header-icon {
    position: absolute;
    top: 10px;
    right: 8px;
    // color: #fff;
    transform: rotate(0deg);
    transition-duration: 0.3s;
}

.accordion .body-accordion {
    /*   display: none; */
    overflow: hidden;
    background-color: #fff;
    border: 0px solid #da9500;
    border-top: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    transition: 150ms ease-out;
}

.accordion .body-inner {
    padding: 8px;
    overflow-wrap: break-word;
    /*   white-space: pre-wrap; */
}

.accordion .header-icon.rotate {
    transform: rotate(180deg);
    transition-duration: 0.3s;
}

.accordion.purple {
    background-color: #8c618d;
}

.accordion.purple .body-accordion {
    border-color: #8c618d;
}

.item-boddy-accordion {
    display: inline-block;
    width: "25%";
}
