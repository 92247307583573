.btn-green {
    border-color: #4caf50;
    background-color: #4caf50;
    &:hover {
        border-color: #368439;
        background-color: #368439;
    }
}
.row-order{
  border-color: #da9500;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.23), 0 0 8px rgb(218, 149, 0);
}
.row-auto-needed{
    border-color: #042efd;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(0, 126, 255, 0.6);
    background-color: #9de6f7;
}

.row-danger{
    border-color: #fd0404;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
    background-color: #fbc0c0;
}
.row-clicked{
    border-color: #d89400;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(0, 255, 220, 0.6);
    background-color: #f7da9d;
}
.row-pay-clicked{
  border-color: #4caf50;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(0, 255, 220, 0.6);
  background-color: #4caf50;
}
.row-added{
	border-color: #4caf50;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(76, 175, 80,.6);
    background-color: #cef5d0;
}
.row-past{
  border-color: #4caf50;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(56, 108, 247,.6);
    background-color: #c0dfff;
}

.hideElement{
	visibility:hidden;
}
.row-merge  {
	position: absolute;
    margin-left: -70px;
    margin-top: 8px;
}

.row-merge-clicked {
	position: absolute;
    margin-left: -70px;
    z-index: 3;
	-webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    margin-top: -3px;
}

.merge-order{
	position: absolute;
    margin-left: -70px;
    z-index: 2;
	-webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    margin-top: -3px;
}

/*
@-webkit-keyframes fadeInLeft {
	0% {
	   opacity: 0;
	   -webkit-transform: translateX(-6px);
	}
	100% {
	   opacity: 1;
	   -webkit-transform: translateX(0);
	}
}

@keyframes fadeInLeft {
	0% {
	   opacity: 0;
	   transform: translateX(-6px);
	}
	100% {
	   opacity: 1;
	   transform: translateX(0);
	}
}

.fadeInLeft {
	-webkit-animation-name: fadeInLeft;
	animation-name: fadeInLeft;
}*/

.fadeInLeft{
  animation: fadeInLeft ease 1s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode:forwards; /*when the spec is finished*/
  -webkit-animation: fadeInLeft ease 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/ 
  -moz-animation: fadeInLeft ease 1s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode:forwards; /*FF 5+*/
  -o-animation: fadeInLeft ease 1s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode:forwards; /*Not implemented yet*/
  -ms-animation: fadeInLeft ease 1s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode:forwards; /*IE 10+*/
}

@keyframes fadeInLeft{
  0% {
    opacity:0;
    transform:  translate(-1500px,0px)  ;
  }
  60% {
    opacity:1;
    transform:  translate(30px,0px)  ;
  }
  80% {
    transform:  translate(-10px,0px)  ;
  }
  100% {
    opacity:1;
    transform:  translate(0px,0px)  ;
  }
}

@-moz-keyframes fadeInLeft{
  0% {
    opacity:0;
    -moz-transform:  translate(-1500px,0px)  ;
  }
  60% {
    opacity:1;
    -moz-transform:  translate(30px,0px)  ;
  }
  80% {
    -moz-transform:  translate(-10px,0px)  ;
  }
  100% {
    opacity:1;
    -moz-transform:  translate(0px,0px)  ;
  }
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity:0;
    -webkit-transform:  translate(-1500px,0px)  ;
  }
  60% {
    opacity:1;
    -webkit-transform:  translate(30px,0px)  ;
  }
  80% {
    -webkit-transform:  translate(-10px,0px)  ;
  }
  100% {
    opacity:1;
    -webkit-transform:  translate(0px,0px)  ;
  }
}

@-o-keyframes fadeInLeft {
  0% {
    opacity:0;
    -o-transform:  translate(-1500px,0px)  ;
  }
  60% {
    opacity:1;
    -o-transform:  translate(30px,0px)  ;
  }
  80% {
    -o-transform:  translate(-10px,0px)  ;
  }
  100% {
    opacity:1;
    -o-transform:  translate(0px,0px)  ;
  }
}

@-ms-keyframes fadeInLeft {
  0% {
    opacity:0;
    -ms-transform:  translate(-1500px,0px)  ;
  }
  60% {
    opacity:1;
    -ms-transform:  translate(30px,0px)  ;
  }
  80% {
    -ms-transform:  translate(-10px,0px)  ;
  }
  100% {
    opacity:1;
    -ms-transform:  translate(0px,0px)  ;
  }
}


.btnAppear{
  animation: btnAppear linear 1s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  -webkit-animation: btnAppear linear 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: btnAppear linear 1s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -o-animation: btnAppear linear 1s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -ms-animation: btnAppear linear 1s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
}

@keyframes btnAppear{
  0% {
    transform:  rotate(0deg) scaleX(1.00) scaleY(1.00) ;
  }
  10% {
    transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  20% {
    transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  30% {
    transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  40% {
    transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  50% {
    transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  60% {
    transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  70% {
    transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  80% {
    transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  90% {
    transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  100% {
    transform:  rotate(0deg) scaleX(1.20) scaleY(1.20) ;
  }
}

@-moz-keyframes btnAppear{
  0% {
    -moz-transform:  rotate(0deg) scaleX(1.00) scaleY(1.00) ;
  }
  10% {
    -moz-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  20% {
    -moz-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  30% {
    -moz-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  40% {
    -moz-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  50% {
    -moz-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  60% {
    -moz-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  70% {
    -moz-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  80% {
    -moz-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  90% {
    -moz-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  100% {
    -moz-transform:  rotate(0deg) scaleX(1.20) scaleY(1.20) ;
  }
}

@-webkit-keyframes btnAppear {
  0% {
    -webkit-transform:  rotate(0deg) scaleX(1.00) scaleY(1.00) ;
  }
  10% {
    -webkit-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  20% {
    -webkit-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  30% {
    -webkit-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  40% {
    -webkit-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  50% {
    -webkit-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  60% {
    -webkit-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  70% {
    -webkit-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  80% {
    -webkit-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  90% {
    -webkit-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  100% {
    -webkit-transform:  rotate(0deg) scaleX(1.20) scaleY(1.20) ;
  }
}

@-o-keyframes btnAppear {
  0% {
    -o-transform:  rotate(0deg) scaleX(1.00) scaleY(1.00) ;
  }
  10% {
    -o-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  20% {
    -o-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  30% {
    -o-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  40% {
    -o-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  50% {
    -o-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  60% {
    -o-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  70% {
    -o-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  80% {
    -o-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  90% {
    -o-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  100% {
    -o-transform:  rotate(0deg) scaleX(1.20) scaleY(1.20) ;
  }
}

@-ms-keyframes btnAppear {
  0% {
    -ms-transform:  rotate(0deg) scaleX(1.00) scaleY(1.00) ;
  }
  10% {
    -ms-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  20% {
    -ms-transform:  rotate(-3deg) scaleX(0.80) scaleY(0.80) ;
  }
  30% {
    -ms-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  40% {
    -ms-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  50% {
    -ms-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  60% {
    -ms-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  70% {
    -ms-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  80% {
    -ms-transform:  rotate(-3deg) scaleX(1.20) scaleY(1.20) ;
  }
  90% {
    -ms-transform:  rotate(3deg) scaleX(1.20) scaleY(1.20) ;
  }
  100% {
    -ms-transform:  rotate(0deg) scaleX(1.20) scaleY(1.20) ;
  }
}

.number-label{
      /* display: block; */
    position: absolute;
    width: 40px;
    height: 38px;
    line-height: 32px;
    border: 2px solid #f5f5f5;
    border-radius: 50%;
    color: #f5f5f5;
    text-align: center;
    text-decoration: none;
    background: #239429;
    box-shadow: 0 0 3px grey;
    /* font-size: 20px; */
    font-weight: bold;
    z-index: 4;
}

.bounceIn{
  animation: bounceIn linear 0.5s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  -webkit-animation: bounceIn linear 0.5s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: bounceIn linear 0.5s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -o-animation: bounceIn linear 0.5s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -ms-animation: bounceIn linear 0.5s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
}

@keyframes bounceIn{
  0% {
    opacity:0;
    transform:  scaleX(1.00) scaleY(1.00) ;
  }
  20% {
    opacity:0.20019759485216782;
    transform:  scaleX(1.23) scaleY(1.14) ;
  }
  60% {
    opacity:0.5994074074074074;
    transform:  scaleX(0.87) scaleY(0.94) ;
  }
  100% {
    opacity:1;
    transform:  scaleX(1.00) scaleY(1.00) ;
  }
}

@-moz-keyframes bounceIn{
  0% {
    opacity:0;
    -moz-transform:  scaleX(1.00) scaleY(1.00) ;
  }
  20% {
    opacity:0.20019759485216782;
    -moz-transform:  scaleX(1.23) scaleY(1.14) ;
  }
  60% {
    opacity:0.5994074074074074;
    -moz-transform:  scaleX(0.87) scaleY(0.94) ;
  }
  100% {
    opacity:1;
    -moz-transform:  scaleX(1.00) scaleY(1.00) ;
  }
}

@-webkit-keyframes bounceIn {
  0% {
    opacity:0;
    -webkit-transform:  scaleX(1.00) scaleY(1.00) ;
  }
  20% {
    opacity:0.20019759485216782;
    -webkit-transform:  scaleX(1.23) scaleY(1.14) ;
  }
  60% {
    opacity:0.5994074074074074;
    -webkit-transform:  scaleX(0.87) scaleY(0.94) ;
  }
  100% {
    opacity:1;
    -webkit-transform:  scaleX(1.00) scaleY(1.00) ;
  }
}

@-o-keyframes bounceIn {
  0% {
    opacity:0;
    -o-transform:  scaleX(1.00) scaleY(1.00) ;
  }
  20% {
    opacity:0.20019759485216782;
    -o-transform:  scaleX(1.23) scaleY(1.14) ;
  }
  60% {
    opacity:0.5994074074074074;
    -o-transform:  scaleX(0.87) scaleY(0.94) ;
  }
  100% {
    opacity:1;
    -o-transform:  scaleX(1.00) scaleY(1.00) ;
  }
}

@-ms-keyframes bounceIn {
  0% {
    opacity:0;
    -ms-transform:  scaleX(1.00) scaleY(1.00) ;
  }
  20% {
    opacity:0.20019759485216782;
    -ms-transform:  scaleX(1.23) scaleY(1.14) ;
  }
  60% {
    opacity:0.5994074074074074;
    -ms-transform:  scaleX(0.87) scaleY(0.94) ;
  }
  100% {
    opacity:1;
    -ms-transform:  scaleX(1.00) scaleY(1.00) ;
  }
}