.menu,
.more {
    position: fixed;
    top: 0;
    padding-top: 60px;
    height: 100vh;
    font-family: Montserrat, sans-serif;
    letter-spacing: 2px;
    color: #fff;
    background-color: #212121;
    transition: all 400ms ease;
    overflow: hidden;
    z-index: 10;
    a {
        display: block;
        position: relative;
        width: 200px;
        height: 60px;
        font-family: Montserrat, sans-serif;
        font-weight: 700;
        letter-spacing: 2px;
        line-height: 60px;
        color: inherit;
        text-decoration: none;
        text-transform: uppercase;
        overflow: hidden;
        cursor: pointer;
        span {
            position: relative;
        }
        i {
            display: inline-block;
            position: relative;
            width: 60px;
            line-height: 60px;
            vertical-align: middle;
            text-align: center;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                width: 6px;
                height: 60px;
                background-color: #da9500;
                transition: all 400ms ease;
            }
        }
        &:before {
            content: '';
            bottom: -100%;
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #424242;
            transition: all 200ms ease;
        }
        &:hover:before {
            bottom: 0;
        }
        &.is-active {
            background-color: lighten(#212121, 5%);
        }
    }
}

.menu {
    left: -200px;
    overflow-y: auto;
    white-space: nowrap;
    a {
        i:before {
            left: -6px;
        }
        &.is-active i:before {
            left: 0;
        }
    }
    &.is-active {
        left: 0;
        box-shadow: 0 0 3px 0 rgba(#000, 0.75);
    }
}

.menu::-webkit-scrollbar {
    width: 5px;
}
 
.menu::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
 
.menu::-webkit-scrollbar-thumb {
  background-color: #4a4a4a;
  outline: 1px solid slategrey;
}

.more {
    right: -200px;
    height: auto;
    a {
        text-align: right;
        i:before {
            right: -6px;
        }
        &.is-active i:before {
            right: 0;
        }
    }
    &.is-active {
        right: 0;
        box-shadow: 0 0 3px 0 rgba(#000, 0.75);
    }
}

.menu-bg {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.75);
    transition: all 400ms ease;
    opacity: 0;
    z-index: 9;
    &.is-active {
        visibility: visible;
        opacity: 1;
    }
}

@media only screen and (min-width: 600px) {
    .menu {
        left: 0;
        width: 60px;
        box-shadow: 0 0 3px 0 rgba(#000, 0.75);
        &.is-active {
            width: 200px;
        }
    }
    .menu-bg {
        display: none;
    }
}