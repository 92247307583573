.data-item-scrollable::-webkit-scrollbar {
    height: 4px !important;
}
.data-table {
    padding: 0 10px 20px 10px;
    .data-row {
        margin-bottom: 20px;
        border: 1px solid #bdbdbd;
        .data-item {
            position: relative;
            padding: 0 10px 0 80px;
            border-top: 1px solid #bdbdbd;
            line-height: 40px;
            text-align: right;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:first-child {
                padding: 0 10px;
                border: none;
                font-weight: 700;
                background-color: #eee;
                text-transform: uppercase;
                text-align: center;
            }
            &:last-child {
                padding: 0 10px;
                text-align: center;
                a, i {
                    margin: 0 10px;
                    color: inherit;
                    text-decoration: none;
                    vertical-align: middle;
                    cursor: pointer;
                }
            }
            &:before {
                content: attr(data-label);
                position: absolute;
                top: 0;
                left: 10px;
                font-size: 10px;
                font-weight: 700;
                text-transform: uppercase;
            }
        }
        .data-item-2 {
            position: relative;
            padding: 0 10px 0 80px;
            border-top: 1px solid #bdbdbd;
            line-height: 40px;
            text-align: right;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:first-child {
                padding: 0 10px;
                border: none;
                font-weight: 700;
                background-color: #eee;
                text-transform: uppercase;
                text-align: center;
            }
            &:last-child {
                padding: 0 10px;
                text-align: center;
                a, i {
                    margin: 0 10px;
                    color: inherit;
                    text-decoration: none;
                    vertical-align: middle;
                    cursor: pointer;
                }
            }
            &:before {
                content: attr(data-label);
                position: absolute;
                top: 0;
                left: 10px;
                font-size: 10px;
                font-weight: 700;
                text-transform: uppercase;
            }
        }
        .data-item-scrollable {
            position: relative;
            padding: 0 10px 0 80px;
            border-top: 1px solid #bdbdbd;
            line-height: 40px;
            text-align: right;
            white-space: nowrap;
            overflow: scroll;
            &:first-child {
                padding: 0 10px;
                border: none;
                font-weight: 700;
                background-color: #eee;
                text-align: center;
            }
            &:last-child {
                padding: 0 10px;
                text-align: center;
                a, i {
                    margin: 0 10px;
                    color: inherit;
                    text-decoration: none;
                    vertical-align: middle;
                    cursor: pointer;
                }
            }
            &:before {
                content: attr(data-label);
                position: absolute;
                top: 0;
                left: 10px;
                font-size: 10px;
                font-weight: 700;
                text-transform: uppercase;
            }
        }
        &.has-headers {
            display: none;            
            font-weight: 700;
            background-color: #eee;
            text-transform: uppercase;
        }
    }
}

/* Tabla para vista nueva */
.data-table-new {
    padding: 0 10px 20px 10px;
    .data-row {
        margin-bottom: 20px;
        border: 1px solid #bdbdbd;
        .data-item {
            position: relative;
            padding: 0 10px 0 80px;
            border-top: 1px solid #bdbdbd;
            line-height: 40px;
            text-align: right;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:first-child {
                padding: 0 10px;
                border: none;
                font-weight: 700;
                background-color: #eee;
                text-transform: uppercase;
                text-align: center;
            }
            &:last-child {
                padding: 0 10px;
                text-align: center;
                a, i {
                    margin: 0 10px;
                    color: inherit;
                    text-decoration: none;
                    vertical-align: middle;
                    cursor: pointer;
                }
            }
            &:before {
                content: attr(data-label);
                position: absolute;
                top: 0;
                left: 10px;
                font-size: 10px;
                font-weight: 700;
                text-transform: uppercase;
            }
        }
        .data-item-2 {
            position: relative;
            padding: 0 10px 0 80px;
            border-top: 1px solid #bdbdbd;
            line-height: 40px;
            text-align: right;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:first-child {
                padding: 0 10px;
                border: none;
                font-weight: 700;
                background-color: #eee;
                text-transform: uppercase;
                text-align: center;
            }
            &:last-child {
                padding: 0 10px;
                text-align: center;
                a, i {
                    margin: 0 10px;
                    color: inherit;
                    text-decoration: none;
                    vertical-align: middle;
                    cursor: pointer;
                }
            }
            &:before {
                content: attr(data-label);
                position: absolute;
                top: 0;
                left: 10px;
                font-size: 10px;
                font-weight: 700;
                text-transform: uppercase;
            }
        }
        .data-item-header {
            position: relative;
            padding: 0 10px 0 80px;
            border-top: 1px solid #bdbdbd;
            line-height: 40px;
            text-align: right;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 18px;
            &:first-child {
                padding: 0 10px;
                border: none;
                font-weight: 700;
                background-color: #eee;
                text-transform: uppercase;
                text-align: center;
            }
            &:last-child {
                padding: 0 10px;
                text-align: center;
                a, i {
                    margin: 0 10px;
                    color: inherit;
                    text-decoration: none;
                    vertical-align: middle;
                    cursor: pointer;
                }
            }
            &:before {
                content: attr(data-label);
                position: absolute;
                top: 0;
                left: 10px;
                font-size: 10px;
                font-weight: 700;
                text-transform: uppercase;
            }
        }
        &.has-headers {
            display: none;            
            font-weight: 700;
            background-color: #eee;
            text-transform: uppercase;
        }
    }
}
/* //////////// Tablas para Modales
////////////////////       */
.data-table-modal {
    padding: 0 0px 20px 0px;
    .data-row-modal {
        margin-bottom: 20px;
        border: 1px solid #bdbdbd;
        .data-item-modal {
            position: relative;
            padding: 0 10px 0 80px;
            border-top: 1px solid #bdbdbd;
            line-height: 40px;
            text-align: right;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:first-child {
                padding: 0 10px;
                border: none;
                font-weight: 700;
                background-color: #eee;
                text-transform: uppercase;
                text-align: center;
            }
            &:last-child {
                padding: 0 10px;
                text-align: center;
                a, i {
                    margin: 0 10px;
                    color: inherit;
                    text-decoration: none;
                    vertical-align: middle;
                    cursor: pointer;
                }
            }
            &:before {
                content: attr(data-label);
                position: absolute;
                top: 0;
                left: 10px;
                font-size: 10px;
                font-weight: 700;
                text-transform: uppercase;
            }
        }
        &.has-headers {
            display: none;            
            font-weight: 700;
            background-color: #eee;
            text-transform: uppercase;
        }
    }
}

.data-empty {
    line-height: 40px;
    font-style: italic;
    text-align: center;
}

.data-end {
    margin: 0 auto;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #da9500;
}

.data-more {
    display: block;
    margin: 0 auto;
    padding: 8px;
    width: 90px;
    border: 2px solid #da9500;
    border-radius: 0;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 2px;
    line-height: 1;
    color: #da9500;
    text-transform: uppercase;
    cursor: pointer;
}

.data-loader {
    position: relative;
    margin: 0 auto;
    width: 40px;
    height: 40px;
    .bounce1,
    .bounce2 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #da9500;
        opacity: 0.6;
        animation: bounce 2s infinite ease-in-out;
    }
    .bounce2 {
        animation-delay: -1s;
    }
}

@keyframes bounce {
    0%, 100% { transform: scale(0.0); }
    50% { transform: scale(1.0); }
}

@media only screen and (min-width: 660px) {
    .menu:not(.is-active) + .main .data-table {
        .data-row {
            display: flex;
            margin-bottom: 0;
            border-top: none;
            .data-item {
                padding: 0 10px;
                width: 25%;
                border: none;
                text-align: center;
                &:before {
                    display: none;
                }
                &:first-child {
                    font-weight: 300;
                    background-color: transparent;
                    text-transform: capitalize;
                }
            }
            .data-item-2 {
                padding: 0 10px;
                width: 50%;
                border: none;
                text-align: center;
                &:before {
                    display: none;
                }
                &:first-child {
                    font-weight: 300;
                    background-color: transparent;
                    text-transform: capitalize;
                }
            }
            &:first-child {
                border-top: 1px solid #bdbdbd;
            }
            &.has-headers {
                display: flex;
                .data-item:first-child {
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
            .data-item-full {
                width: 100%;
            }
            .data-item-half {
                width: 50%;
            }
            .data-item-third {
                width: 33%;
            }
            .data-item-quarter {
                width: 25%;
            }
            .data-item-fifth {
                width: 20%;
            }
            .data-item-sixth {
                width: 16.67%;
            }
            .data-item-scrollable {
                padding: 0 10px;
                width: 25%;
                border: none;
                text-align: center;
                &:before {
                    display: none;
                }
                &:first-child {
                    font-weight: 300;
                    background-color: transparent;
                    text-transform: capitalize;
                }
            }
        }
    }
    .menu:not(.is-active) + .main .data-end,
    .menu:not(.is-active) + .main .data-more,
    .menu:not(.is-active) + .main .data-loader,
    .menu:not(.is-active) + .main .data-empty {
        margin-top: 20px;
    }
    .menu:not(.is-active) + .main .data-table-modal {
        .data-row-modal {
            display: flex;
            margin-bottom: 0;
            border-top: none;
            .data-item-modal {
                padding: 0 10px;
                width: 65%;
                border: none;
                text-align: center;
                &:before {
                    display: none;
                }
                &:first-child {
                    font-weight: 300;
                    width:35%;
                    background-color: transparent;
                    text-transform: capitalize;
                }
            }
            &:first-child {
                border-top: 1px solid #bdbdbd;
            }
            &.has-headers {
                display: flex;
                .data-item:first-child {
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
        }
    }

    
}

@media only screen and (min-width: 800px) {
    .data-table {
        .data-row {
            display: flex;
            margin-bottom: 0;
            border-top: none;
            .data-item {
                padding: 0 10px;
                width: 25%;
                border: none;
                text-align: center;
                &:before {
                    display: none;
                }
                &:first-child {
                    font-weight: 300;
                    background-color: transparent;
                    text-transform: capitalize;
                }
            }
            .data-item-2 {
                padding: 0 10px;
                width: 50%;
                border: none;
                text-align: center;
                &:before {
                    display: none;
                }
                &:first-child {
                    font-weight: 300;
                    background-color: transparent;
                    text-transform: capitalize;
                }
            }
            &:first-child {
                border-top: 1px solid #bdbdbd;
            }
            &.has-headers {
                display: flex;
                .data-item:first-child {
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
            .data-item-scrollable {
                padding: 0 10px;
                width: 25%;
                border: none;
                text-align: center;
                &:before {
                    display: none;
                }
                &:first-child {
                    font-weight: 300;
                    background-color: transparent;
                    text-transform: capitalize;
                }
            }
        }
    }
    .main .data-end,
    .main .data-more,
    .main .data-loader,
    .main .data-empty {
        margin-top: 20px;
    }

        .data-table-modal {
        .data-row-modal {
            display: flex;
            margin-bottom: 0;
            border-top: none;
            .data-item-modal {
                padding: 0 10px;
                width: 75%;
                border: none;
                text-align: center;
                &:before {
                    display: none;
                }
                &:first-child {
                    font-weight: 300;
                     width: 25%;
                    background-color: transparent;
                    text-transform: capitalize;
                }
            }
            &:first-child {
                border-top: 1px solid #bdbdbd;
            }
            &.has-headers {
                display: flex;
                .data-item:first-child {
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
        }
    }
}