
$swift-ease-out-duration: .4s !default;
$swift-ease-out-timing-function: cubic-bezier(.25, .8, .25, 1) !default;
$swift-ease-out: all $swift-ease-out-duration $swift-ease-out-timing-function !default;

$swift-ease-in-duration: .3s !default;
$swift-ease-in-timing-function: cubic-bezier(.55, 0, .55, .2) !default;
$swift-ease-in: all $swift-ease-in-duration $swift-ease-in-timing-function !default;

.md-ink-ripple {
  pointer-events: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-mask-image: radial-gradient(circle, white 100%, black 100%);
  transition: $swift-ease-in;
}

.md-ripple {
  position: absolute;
  background-color: currentColor;
  border-radius: 50%;
  opacity: .2;
  transform: scale(0) translateZ(0);
  transition: none;
  will-change: background-color, opacity, transform, width, height, top, left;

  &.md-active {
    animation: ripple 1s $swift-ease-out-timing-function forwards;

    &.md-fadeout {
      opacity: 0 !important;
      transition: $swift-ease-out;
      transition-duration: .6s;
    }
  }
}

@keyframes ripple {
  to {
    transform: scale(2.2) translateZ(0);
  }
}
