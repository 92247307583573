.vue__time-picker{
	display:inline-block;
	position:relative;
	font-size:1em;
	width:10em;
	font-family:sans-serif;
	vertical-align:middle
}
.vue__time-picker *{
	-webkit-box-sizing:border-box;
	box-sizing:border-box
}
.vue__time-picker input.display-time{
	border:1px solid #d2d2d2;
	width:12em;
	height:2.2em;
	padding:.3em .5em;
	font-size:1em
}
.vue__time-picker input.display-time.disabled,.vue__time-picker input.display-time:disabled{
	color:#d2d2d2
}
.vue__time-picker .clear-btn{
	position:absolute;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient:vertical;
	-webkit-box-direction:normal;
	-ms-flex-flow:column nowrap;
	flex-flow:column nowrap;
	-webkit-box-pack:center;
	-ms-flex-pack:center;
	justify-content:center;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	top:0;
	right:25%;
	bottom:0;
	width:1.3em;
	z-index:33;
	font-size:1.1em;
	line-height:1em;
	vertical-align:middle;
	color:#d2d2d2;
	background:hsla(0,0%,100%,0);
	text-align:center;
	font-style:normal;
	-webkit-margin-before:-.15em;
	-webkit-transition:color .2s;
	transition:color .2s
}
.vue__time-picker .clear-btn:hover{
	color:#797979;
	cursor:pointer
}
.vue__time-picker .clear-btn:active{
	outline:0
}
.vue__time-picker .time-picker-overlay{
	z-index:32;
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0
}
.vue__time-picker .dropdown{
	position:absolute;
	z-index:35;
	top:calc(2.2em + 2px);
	bottom: auto;
	/*top: auto; bottom: 100%;*/
	left:0;
	background:#fff;
	-webkit-box-shadow:0 1px 6px rgba(0,0,0,.15);
	box-shadow:0 1px 6px rgba(0,0,0,.15);
	width:12em;
	height:10em;
	font-weight:400
}
.vue__time-picker .dropdown .select-list{
	width:12em;
	height:10em;
	overflow:hidden;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-orient:horizontal;
	-webkit-box-direction:normal;
	-ms-flex-flow:row nowrap;flex-flow:row nowrap;
	-webkit-box-align:stretch;
	-ms-flex-align:stretch;align-items:stretch;
	-webkit-box-pack:justify;
	-ms-flex-pack:justify;
	justify-content:space-between
}
.vue__time-picker .dropdown .select-list:active,
.vue__time-picker .dropdown .select-list:focus{
	outline:0
}
.vue__time-picker .dropdown ul{
	padding:0;
	margin:0;
	list-style:none;
	outline:0;
	-webkit-box-flex:1;
	-ms-flex:1 1 0.00001px;
	flex:1 1 0.00001px;
	overflow-x:hidden;
	overflow-y:auto
}
.vue__time-picker .dropdown ul.apms,
.vue__time-picker .dropdown ul.minutes,
.vue__time-picker .dropdown ul.seconds{
	border-left:1px solid #fff
}
.vue__time-picker .dropdown ul li{
	list-style:none;
	text-align:center;
	padding:.3em 0;
	color:#161616
}
.vue__time-picker .dropdown ul li:not(.hint):not([disabled]):focus,
.vue__time-picker .dropdown ul li:not(.hint):not([disabled]):hover{
	background:rgba(0,0,0,.08);
	color:#161616;
	cursor:pointer
}
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover{
	background:#41b883;
	color:#fff
}
.vue__time-picker .dropdown ul li[disabled],
.vue__time-picker .dropdown ul li[disabled]:hover{
	background:transparent;
	opacity:.3;
	cursor:not-allowed
}
.vue__time-picker .dropdown .hint{
	color:#a5a5a5;
	cursor:default;
	font-size:.8em
}