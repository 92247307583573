.btn .loader {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 14px;
    transition: all 400ms ease;
    transform: translateY(-50%);
    opacity: 0;
    & > div {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: rgba(#fff, 0.7);
        vertical-align: middle;
        animation: bouncedelay  1400ms infinite ease-in-out both;
        &.bounce1 {
            animation-delay: -320ms;
        }
        &.bounce2 {
            animation-delay: -160ms;
        }
    }
}

.dropdown .loader {
    transition: all 400ms ease;
    text-align: center;
    & > div {
        display: inline-block;
        margin: 9px 3px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: rgba(#da9500, 0.7);
        vertical-align: middle;
        animation: bouncedelay  1400ms infinite ease-in-out both;
        &.bounce1 {
            animation-delay: -320ms;
        }
        &.bounce2 {
            animation-delay: -160ms;
        }
    }
}

@keyframes bouncedelay {
    0%, 80%, 100% { 
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% { 
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}

.map-top.map-loading {
    background-color: black;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position:absolute;
    height: 100%;
    width: 100%;
    opacity: 0.5;
}

.map-top .map-loader {
    position:relative;
    transition: all 400ms ease;
    text-align: center;
    & > div {
        display: inline-block;
        margin: 9px 3px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: rgba(#fff, 0.7);
        vertical-align: middle;
        animation: bouncedelay  1400ms infinite ease-in-out both;
        &.bounce1 {
            animation-delay: -320ms;
        }
        &.bounce2 {
            animation-delay: -160ms;
        }
    }
}