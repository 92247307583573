// Variables
$bg-color: #555555;
$red-color: #9c0000;

$inbox-primary: #EDF2F4;
$inbox-secondary: lighten($bg-color, 7%);
$inbox-width: 100%;
$inbox-height: 100%;
$inbox-radius: 4px;
$inbox-border-radius: 24px;

// Universal Selector
.chat-general {
  margin: 0; padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

// Container
.chat-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
}

// Chat seleccionado
.chat-seleccionado {
  background: $inbox-primary !important;
  color: $bg-color !important;
  font-weight: bold !important;
  border-radius: 56px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

@media screen and (min-width: 320px) {
  .chat-footer {
    flex: 0 1 12rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    input[type="text"] {
      margin-left: 0.5em !important;
      width: auto !important;
      padding: 0.2em !important;
    }
    input[type="submit"] {
      margin: 0 1em  !important;
      width: auto !important;
    }
  }
  .chat-aside {
    display: none !important;
  }
  .header-chat-mobile {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media screen and (min-width: 321px) and (max-width:480px){
  .chat-footer {
    flex: 0 1 5rem !important;
  }
  .chat-aside {
    display: none !important;
  }
}

@media screen and (min-width: 481px) and (max-width:720px) {
  .inbox {
    flex-direction: column;
  }
  .chat-main {
    height: 75% !important;
  }
  .chat-footer {
    flex: 0 1 5rem !important;
  }
}

@media screen and(min-width: 721px) and (max-width:1920px) {
  .header-chat-mobile {
    display: none !important;
  }
  .chat-aside {
    display: flex !important;
  }
  .chat-footer {
    flex: 0 1 5rem !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    input[type="text"] {
      padding: 0.2em !important;
    }
  }
}

@media only screen and (max-width: 0px) and (min-width: 365px) {
  
}

.button-send {
  background: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  .anticon {
    color: $bg-color;
  }
}

// Inbox Styles
.inbox {
  background: $inbox-primary;
  width: $inbox-width;
  height: $inbox-height;
  display: flex;
  border-radius: $inbox-border-radius;

  // Aside Styles
  .chat-aside {
    // background: $inbox-secondary;
    flex: 1 1 25%;
    border-top-left-radius: $inbox-radius;
    border-bottom-left-radius: $inbox-radius;
    overflow-y: auto;
    margin: 16px;
    border-radius: $inbox-border-radius;
    background: $bg-color;
    flex-direction: column;
    .header-aside {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 24px;
      padding-right: 20px;
      font-size: 24px;
      font-weight: bold;
      color: #FFF;
      background: $bg-color;
    }
    .avatar {
      width: auto;
      height: auto;
      background: #bd0000;
      border: 2px solid #FFF;
      border-radius: 100%;
      margin-left: 4px;
      text-align: center;
      padding-left: 8px;
      padding-right: 8px;
    }
    .new-chat {
      background: darken($inbox-secondary, 12%);
    }
    // Li Elements In Aside
    li {
      background: darken($inbox-secondary, 3%);
      list-style: none;
      color: #FFF;
      display: flex;
      justify-content: space-between;
      padding: 0 2em;
      align-items: center;
      height: 5em;
      font-size: 0.8em;
      cursor: pointer;
      &:hover {
        background: darken($inbox-secondary, 5%);
      }
    }
    input[type="text"] {
      border: none;
      background: transparent;
      padding: 0.8em;
      outline: none;
      color: rgb(204, 204, 204);
      width: 100%;
      border-bottom: #EDF2F4 1px solid;
      &::placeholder {
        color: #AAA;
      }
    }
    .alt-container {
      background: $inbox-primary;
      margin: 0.7rem;
      border-radius: $inbox-border-radius / 2;
      padding: 0.5rem 0rem;
      .alt-subheader {
        padding: 0.5rem 0.7rem;
        border-bottom: rgb(29, 29, 29) 2px dotted;
        font-weight: bold;
      }
      .alt-subitem {
        padding: 0.3rem 0.7rem;
      }
    }
    .alt-container-btn {
      margin: 0.7rem;
      border-radius: $inbox-border-radius / 2;
      padding: 0.5rem 0rem;
      background: transparent !important;
    }
  }

  // Our Main Content Where Conversations Will Be Listed
  .chat-main {
    background: $inbox-primary;
    flex: 1 1 75%;
    border-top-right-radius: $inbox-radius;
    border-bottom-right-radius: $inbox-radius;
    margin: 16px;
    display: flex;
    flex-direction: column;
    .header-chat-mobile {
      background: $bg-color;
      flex: 0 1 auto;
      display: none;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 14px;
      padding-right: 14px;
      border-radius: $inbox-border-radius;
      p {
        color: $inbox-primary;
        font-weight: bold;
        margin-bottom: 0px;
        padding: 0px;
      }
      ant-icon {
        color: $inbox-primary !important;
      }
    }
    .message-wrap {
      margin-top: 1em;
      margin-bottom: 1em;
      overflow-y: auto;
      flex: 1 1 auto;
    }
    .message-wrap::-webkit-scrollbar {
      width: 12px;
    }
    .message-wrap::-webkit-scrollbar-thumb {
      background-color: $bg-color;
    }
    .container-owner {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 12px;
    }
    .container-normal {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 12px;
    }
    .message-owner {
      background-color: $bg-color;
      // background: darken($inbox-secondary, 12%);
      width: fit-content;
      margin-top: 0.1em;
      margin-bottom: 0.1em;
      margin-right: 2em;
      margin-left: 6em;
      padding: 1.5em;
      padding-top: 1em;
      padding-bottom: 1em;
      border-radius: 56px;
      border-bottom-right-radius: 0px;
      opacity: 1;
      transition: opacity ease-in-out .45s;
      p {
        font-size: 1em;
        color: white;
        font-weight: 300;
        margin-bottom: 0px !important;
      }
      img {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        transform: translateX(-70px) translateY(-20px);
        float: left;
      }
      .fecha-mensaje {
        color: white !important;
        filter: brightness(80%);
      }
    }
    .message {
      background-color: white;
      // background: $inbox-secondary;
      width: fit-content;
      padding: 1.5em;
      padding-top: 1em;
      padding-bottom: 1em;
      border-radius: 56px;
      border-bottom-left-radius: 0px;
      opacity: 1;
      transition: opacity ease-in-out .45s;
      box-shadow: 1px 3px 8px #888;
      p {
        font-size: 1em;
        color: black;
        font-weight: 300;
        margin-bottom: 0px !important;
      }
      img {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        transform: translateX(-70px) translateY(-20px);
        float: left;
      }
      .fecha-mensaje {
        color: #8a8a8a !important;
      }
      margin-top: 0.1em;
      margin-bottom: 0.1em;
      margin-right: 6em;
      margin-left: 2em;
    }
    .fecha-mensaje {
      display: inline !important;
      margin-right: 0.5em !important;
      font-size: 0.68em !important;
    }
    .visto-tag-mensaje {
      color: #b8b8b8;
      display: inline !important;
      margin-left: 1em !important;
      float: right;
      font-size: 1.05em !important;
    }
    .visto-tag-mensaje-complete {
      color: white;
      display: inline !important;
      float: right;
      font-size: 1.05em !important;
    }
    // Our Footer To Our Main Content, Contains The Submit Form
    .chat-footer {
      background: white;
      flex: 0 1 5rem;
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      border-radius: 16px;
      box-shadow: 1px 2px 4px #888;
      input[type="text"] {
        border: none;
        border-bottom: darken($inbox-primary, 10%) 1px solid;
        background: transparent;
        padding: 0.4em;
        margin-left: 1em;
        outline: none;
        color: rgb(54, 54, 54);
        width: 100%;
        flex: 1 1 auto;
      }
      input[type="submit"] {
        background: $inbox-secondary;
        color: #FFF;
        width: 17%;
        border-radius: 4px;
        // padding: 0.8em;
        margin: 0 1em;
        border: none;
        cursor: pointer;
        appearance: none;
      }
      form {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

// Initial Start Up Screen
.init {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
  text-align: center;
  i {
    font-size: 5em;
    color: $inbox-secondary;
  }
  h4 {
    margin: 1em auto;
    color: $inbox-secondary;
  }
}

// Custom Animation Loading Screen
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  height: 100%;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: -1;
  text-align: center;
  p {
    background: $inbox-secondary;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    animation: loading 2000ms cubic-bezier(0.470, 0.000, 0.745, 0.715) infinite;
    &:before {
      content: '';
      background: $inbox-secondary;
      width: 40px;
      height: 40px;
      opacity: 1;
      display: block;
      transform: translateX(-10px) translateY(-10px);
      border-radius: 100%;
      animation: loading 2000ms cubic-bezier(0.470, 0.000, 0.745, 0.715) infinite;
      animation-delay: 50ms;
    }
  }
  h4 {
    margin: 1em auto;
    color: $inbox-secondary;
  }
}

// Loading Animation
@keyframes loading {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}

// Custom Scrollbars
::-webkit-scrollbar {
    width: 0px;
}
 
::-webkit-scrollbar-track {
  background: transparent;
}
 
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.1);
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  height: 100%;
  text-align: center;
  i {
    font-size: 5em;
    color: $inbox-primary;

  }
  h4 {
    margin: 1em auto;
    color: $inbox-primary;
  }
}

.modal-chat {
  max-width: 80% !important;
}

.loader-aside {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  height: 100%;
  text-align: center;
  margin: 0 auto;
  p {
    background: $inbox-primary;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    animation: loading 2000ms cubic-bezier(0.470, 0.000, 0.745, 0.715) infinite;
    &:before {
      content: '';
      background: $inbox-primary;
      width: 40px;
      height: 40px;
      opacity: 1;
      display: block;
      transform: translateX(-10px) translateY(-10px);
      border-radius: 100%;
      animation: loading 2000ms cubic-bezier(0.470, 0.000, 0.745, 0.715) infinite;
      animation-delay: 50ms;
    }
  }
  h4 {
    margin: 1em auto;
    color: $inbox-primary;
  }
}

.close-btn {
  position: absolute;
  background: rgb(201, 0, 0);
  color: white;
  top: 2px;
  right: 2px;
  z-index: 1;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 50%;
}

.chat-btn-tracking {
  // background-color : #03a84e;
  color: white;
  padding: 15px 40px;
  border-radius: 4px;
  border: none;
  font-size: 18px;
}

.chat-btn-warning {
  background-color : $red-color !important;
  color: white !important;
  padding: 15px 20px;
  border-radius: 4px;
  font-size: 18px;
  border: none;
  -webkit-animation: blink 1s infinite;
  -moz-animation: blink 1s infinite;
  -o-animation: blink 1s infinite;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 24% {
    background-color: #9c0000 !important;
  }
  25%, 49% {
    background-color: #a30000 !important;
  }
  50%, 74% {
    background-color: #b10000 !important;
  }
  75%, 100% {
    background-color: #bd0000 !important;
  }
}

@-webkit-keyframes blink {
  0%, 24% {
    background-color: #9c0000 !important;
  }
  25%, 49% {
    background-color: #a30000 !important;
  }
  50%, 74% {
    background-color: #b10000 !important;
  }
  75%, 100% {
    background-color: #bd0000 !important;
  }
}

#chat-btn-container {
  position: fixed;
  bottom: -4px;
  right: 50px;
  z-index: 1;
}

.ver-mas-mensajes {
  background: #00721d !important;
  color: #FFF;
  width: auto;
  border-radius: 4px;
  padding: 0.2em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin: 0 1em;
  border: none;
  cursor: pointer;
  appearance: none;
}
