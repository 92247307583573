.square {
    float:left;
    position: relative;
    width: 15%;
    padding-bottom : 9.875%; /* = width for a 1:1 aspect ratio */
    margin:1.66%;
    overflow:hidden;
}

.content {
    font-family: Montserrat, sans-serif;
    position:absolute;
    height:90%; /* = 100% - 2*5% padding */
    width:90%; /* = 100% - 2*5% padding */
    padding: 5%;
    text-align:center
    
}
.sub-table{
    display:sub-table;
    width:100%;
    height:100%;
}
.sub-table-cell{
    display:sub-table-cell;
    vertical-align:middle;
}
.title-label{
    font-family: Montserrat, sans-serif;
    font-weight:800;
    font-size:150%;
    color: white;
}
