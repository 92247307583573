.toaster {
    position: fixed;
    z-index: 40;
    .toast {
        position: fixed;
        bottom: 20px;
        right: 20px;
        padding: 7px 10px 7px 40px;
        max-width: calc(100% - 40px);
        line-height: 1.5;
        color: #fff;
        box-shadow: 0 0 3px rgba(0,0, 0, 0.75);
        text-align: right;
        transform: translateX(100%);
        opacity: 0;
        transition: all 400ms ease;
        i {
            position: absolute;
            top: 0;
            left: 10px;
            line-height: 35px;
            font-size: 20px;
        }
        &.success {
            background-color: #4caf50;
        }
        &.error {
            background-color: #f44336;
        }
        &.warning {
            background-color: #ffb300;
        }
        &.info {
            background-color: #1976d2;
        }
        &.is-visible {
            transform: translateX(0);
            opacity: 1;
        }
    }
}

@media only screen and (min-width: 500px) {
    .toaster .toast {
        max-width: 460px;
    }
}