@import '_variables.scss';

.combobox {
  width: 100%;
  height: $select-height;
  position: relative;
  border:none;
  color: rgba(0,0,0,0.45);
  border-bottom: 1px solid #9e9e9e;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;

  &.active
  {
    color:black;
  }

  option
  {
    color:black;
  }
  option:first-child {
    color: rgba(0,0,0,0.45);
  }

  select {
    position: absolute;
    left: -999em;
  }
}

.combobox2 {
  width: 100%;
  height: $select-height;
  position: relative;
  border:none;
  color: rgba(0,0,0,0.45);
  border-bottom: 1px solid #9e9e9e;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;

  &.active
  {
    color:black;
  }

  option
  {
    color:black;
  }

  select {
    position: absolute;
    left: -999em;
  }
}
