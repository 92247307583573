.main {
    height: 100vh;
    padding: 20px 10px 0 10px;
    background-color: #fff;
    transition: all 400ms ease;
    .logo {
        display: block;
        margin: 0 auto;
        padding: 20px;
        width: 250px;
    }
    &.has-menu {
        padding: 80px 10px 0 10px;
    }
}

.title {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 20px 10px;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    .btn {
        margin: 0;
    }
}
.drivers_header
{
    display: flex;
    .title
    {
        flex:1;
    }
}

.label_key {
    color: rgba(0,0,0,0.70);
    display: inline;
    margin-left: 10px;
    margin-right: 50px;
}

.label_value {
    color: rgba(0,0,0,0.95);
}

@media only screen and (min-width: 600px) {
    .main.has-menu {
        padding-left: 70px;
    }
    .menu.is-active + .main.has-menu {
        padding-left: 210px;
    }
}

/* Dropdown Button */
.dropdownbtn {
  background-color: #81ef85;
  color: black;
  padding: 2px;
  margin-right: 2px;
  font-size: 16px;
  border: none;
}

.dropdownSustituteBtn {
  background-color: #C076F7;
  color: black;
  padding: 2px;
  margin-right: 2px;
  font-size: 16px;
  border-right: 10px solid #030C76;
}

.dropdownbtnWithPlus {
  background-color: #81ef85;
  color: black;
  padding: 2px;
  margin-right: 2px;
  font-size: 16px;
  border-right: 10px solid #030C76;
}

.dropdownbtnWithMinus {
  background-color: #81ef85;
  color: black;
  padding: 2px;
  margin-right: 2px;
  font-size: 16px;
  border-right: 10px solid #f22c39;
}

.dropdownbtn2 {
  background-color: #90caf9;
  color: black;
  padding: 2px;
  margin-right: 2px;
  font-size: 16px;
  border: none;
}

.dropdownbtn2WithPlus {
  background-color: #90caf9;
  color: black;
  padding: 2px;
  margin-right: 2px;
  font-size: 16px;
  border-right: 10px solid #030C76;
}

.dropdownbtn2WithMinus {
  background-color: #90caf9;
  color: black;
  padding: 2px;
  margin-right: 2px;
  font-size: 16px;
  border-right: 10px solid #f22c39;
}

/* The container <div> - needed to position the dropdown content */
.dropdowncontainer {
  position: relative;
  display: -webkit-inline-box;
}

.dropdowndeletedcontainer{
  position: relative;
  display: -webkit-inline-box;
}

.dropdownDeleted {
  background-color: #f9a0a0;
  color: black;
  padding: 2px;
  margin-right: 2px;
  font-size: 16px;
  border: none;
}

.dropdownDelayed {
  background-color: #F6CB1D;
  color: black;
  padding: 2px;
  margin-right: 2px;
  font-size: 16px;
  border: none;
}

.dropdownDelayedWithPlus {
  background-color: #F6CB1D;
  color: black;
  padding: 2px;
  margin-right: 2px;
  font-size: 16px;
  border-right: 10px solid #030C76;
}

.dropdownDelayedWithMinus {
  background-color: #F6CB1D;
  color: black;
  padding: 2px;
  margin-right: 2px;
  font-size: 16px;
  border-right: 10px solid #f22c39;
}



/* Dropdown Content (Hidden by Default) */
#dropdowncontent {
  display: none;
  position: relative;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  line-height: 10px;
}

/* Links inside the dropdown */
#dropdowncontent a {
  color: black;
  text-decoration: none;
  display: block;
  font-size: 10px;
  line-height: 10px;
}

/* Change color of dropdown links on hover */
#dropdowncontent a:hover {background-color: #ddd;}

/* Show the dropdown menu on hover */
.dropdowncontainer:hover #dropdowncontent {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdowncontainer:hover .dropdownbtn {background-color: #72cc75;}